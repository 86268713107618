import React, { useEffect, useRef } from 'react';
import { getAccessToken } from '@resources/helpers/request';
import { wait } from '@resources/helpers/saga';

export const FileView = (props: { uuid: string }) => {
    const ref = useRef<HTMLImageElement | null>(null);

    const fetchImage = () => {
        const url = `${process.env.HOST_API}/v1/files/${props.uuid}`;
        const headers = { Authorization: `Bearer ${getAccessToken()}` };

        return fetch(url, { headers })
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error(res.statusText);
                }

                return res.blob();
            })
            .then((blob) => {
                ref.current.src = URL.createObjectURL(blob);
            })
            .catch((e) => {
                throw e;
            });
    };

    useEffect(() => {
        fetchImage();
    }, []);

    return <img style={{ width: 150 }} ref={ref} alt="" />;
};

export const SignatureImage = (props: { uuid: string }) => {
    const ref = useRef<HTMLImageElement | null>(null);

    const fetchImage = () => {
        const url = `${process.env.HOST_API}/v1/signature/${props.uuid}/image`;
        const headers = { Authorization: `Bearer ${getAccessToken()}` };

        return fetch(url, { headers })
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error(res.statusText);
                }

                return res.blob();
            })
            .then((blob) => {
                ref.current.src = URL.createObjectURL(blob);
            })
            .catch((e) => {
                throw e;
            });
    };

    const listen = async () => {
        while (true) {
            try {
                await fetchImage();
                break;
            } catch (e) {
                await wait(100);
            }
        }
    };

    useEffect(() => {
        listen();
    }, []);

    return <img style={{ width: 150 }} ref={ref} alt="" />;
};
